<template>
	<div id="payPage">
		<div  class="guide-alipay" v-show="showAliGuide" @click="showAliGuide = false;">
			<img src="@/assets/guide_alipay.png">
		</div>
		<div v-if="step===1"  v-show="showPayBtn" class="newcard">
			<!-- <h2 style="font-size:5vw;margin-top:5vw"> ↓ 优惠码 ↓</h2>
			<hr>
			<cube-input v-model="invitationCode" :maxlength="10" placeholder="请输入优惠码" />
			<hr>
			<h2 style="font-size:4vw">{{ invitationCode?invitationCode:'↑ 未输入优惠码 ↑' }}</h2>
			<hr> -->
			<!-- <span> {{ invitationCode?'↑↑↑ 优惠码 ↑↑↑':'如果您有优惠码，可以获得优惠哟' }}</span> -->
			<!-- <span> ↑↑↑ 优惠码 ↑↑↑</span> -->
			<!-- <button style="height:5vh ;width: 40 vw;margin-top:5vh;background-color:white"> 一键求助小刀客服 </button> -->
			<div class="btnLine">
				<van-button style="margin-top:1.5vh;margin-bottom:2vh" color="#217bfb" @click="toPay(1)" :disabled="isPaying"> 支付宝支付 </van-button>
				<van-button v-if="false" style="margin-top:1.5vh;margin-bottom:2vh" color="#11b855" @click="toPay(2)" :disabled="isPaying"> 微信支付 </van-button>
			</div>
			<!-- <span>一键求助小刀客服</span> -->
		</div>
		<div v-if="step===2" class="newcard">
			<!-- <span style="width:61.8vw;text-align:center">恭喜你，距离开启一刀智投还差<span style="color:orange">最后一步</span></span>
      <span style="">扫码支付 <span style="color:red"> 8800元/年</span> ， 开启会员体验</span> -->
			<span style="text-align:center;margin-top:1.5vh"> ↓↓保存二维码↓↓ </span>
			<span style="text-align:center;margin-top:1.5vh"> 支付宝【扫一扫】→从相册打开</span>
			<span style="text-align:center;margin-top:1.5vh">保存不成功时,请截屏保存二维码</span>
			<img id="qrcodeimg" src="@/assets/alipay_20200315135751.png" style="width:61.8vw" alt srcset>
			<!-- <span style="color:grey;margin-bottom:1vh"> <strong style="color:grey">正式版为 9888元/年 </strong></span> -->
			<!-- <span style="color:grey;margin-bottom:1vh"> <strong style="color:red">活动价 3988/年 </strong></span> -->
			<span style="color:grey;margin-top:1.5vh">支付成功后,请联系客服开通正式版</span>
			<!-- <button style="height:5vh ;width: 40 vw;margin-top:5vh;background-color:white"> 一键求助小刀客服 </button> -->
			<van-button style="margin-top:1.5vh;margin-bottom:2vh" color="#f2826a" @click="help()">一键求助小刀客服</van-button>
			<!-- <span>一键求助小刀客服</span> -->
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import * as sysTools from '../../utils/sysTools'

import { Button, Uploader, Overlay, Popup, Toast, Notify } from 'vant'
import { Input } from 'cube-ui'
import {
	getWeOpenId,
  	islogin,
	addcheck,
	paylog,
	pay_vip,
	pay_vip2
} from '@/api/theApi'
import request from '@/utils/http'
Vue.use(Popup)
export default {
	name: 'Vip',
	components: {
		[Button.name]: Button,
		[Uploader.name]: Uploader,
		[Overlay.name]: Overlay,
		[Input.name]: Input
		// [Popup.name]: Popup
	},
	data() {
		return {
			showAliGuide: false,
			isPaying: false,
			ispay: false,
			readychk: false,
			isshow: false,
			ordernumber: '',
			titlelable: false,
			username: '',
			step: 1,
			invitationCode: '',
			phone: '',
			isMobile: false,
			appid: 'wxac4d033679f1b1a9',
			openId: '',
			code: '',
			isWechat: false,
			showPayBtn: false
		}
	},
	created() {
		if (Object(this.$route.query).hasOwnProperty('iv')) {
			this.invitationCode = this.$route.query.iv
		}
		if (Object(this.$route.query).hasOwnProperty('p')) {
			this.phone = sysTools.b64decode(this.$route.query.p)
		}
		this.isMobile = !this.IsPC();
		this.init();
	},
	mounted() {
		let vm = this;
		this.username = localStorage.getItem('username')
		this.show()

		setTimeout(function() {
			vm.showPayBtn = true;
			if (typeof WeixinJSBridge !== "undefined") {
				let code = vm.$route.query.code;
				vm.isWechat = true;
				if (code) {
					vm.code = code;
					vm.isPaying = true;
					vm.getOpenId();
				}else{
					vm.initScope();
				}
			}
		}, 500)
	},
	methods: {
		async init() {
			// todo 获取lock状态， 赋值给 lockmoney ,洗澡
			// 登陆是否过期
			const theislogin = await islogin()
			this.islogin = theislogin.login_status

			if (!theislogin.login_status) {

				Toast.fail('登陆过期')
				setTimeout(() => {
					this.$router.push({
						path: '/login'
					})
				}, 2000)
			}
		},
		initScope() {
			let hostname = location.origin + '/pay';
			// let hostname = "https://yidao.ainvestcn.com/pay";
			location.href = ("https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + this.appid + "&redirect_uri=" + hostname + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirec");
		},
		async getOpenId() {
			let rslt = await getWeOpenId({
				code: this.code
			});
			this.isPaying = false;
			if (rslt.errcode) {
				Notify({ type: 'warning', message: rslt.msg });
			} else {
				this.openId = rslt.data.openid;
			}
		},
		IsPC: function() {
			var userAgentInfo = navigator.userAgent;
			var Agents = ["Android", "iPhone",
				"SymbianOS", "Windows Phone",
				"iPad", "iPod"
			];
			var flag = true;
			for (var v = 0; v < Agents.length; v++) {
				if (userAgentInfo.indexOf(Agents[v]) > 0) {
					flag = false;
					break;
				}
			}
			return flag;
		},
		// 上传优惠码 和 用户名， type- 1支付宝，2微信
		async toPay(type) {
			if (this.isPaying) {
				return;
			}
			if (type === 2) {

				if (typeof WeixinJSBridge === "undefined") {
					Notify({ type: 'warning', message: '请在微信内打开' });
					return;
				}
				if (!this.code || !this.openId) {
					Notify({ type: 'warning', message: "参数缺失"});
					return;
				}
			}

			console.log(type === 1 , this.isWechat)
			if (type === 1 && this.isWechat) {
				this.showAliGuide = true;
				
				// Notify({ type: 'warning', message: '微信内不支持此操作，请用浏览器打开'});
				return;
			}
			this.isPaying = true;
			const rslt = await pay_vip2({
				platform: type === 1 ? 'alipay' : 'wepay',
				isMobile: this.isMobile,
				username: this.username,
				couponCode: this.invitationCode,
				openId: this.openId
			})
			this.isPaying = false;
			if (type === 1) {
				this.toAlipay(rslt);
			} else {
				this.toWepay(rslt);
			}
		},
		toAlipay(rslt) {
			if (rslt.code === -1) {
				Notify({ type: 'warning', message: rslt.msg });
			} else {
				location.href = ('/v3/pay_vip2?platform=alipay&isMobile=' + this.isMobile + '&username=' + this.username + '&couponCode=' + this.invitationCode);
			}
		},
		toWepay(rslt) {
			if (rslt.code === 20000) {
				this.onBridgeReady(rslt.data);
			} else {
				Notify({ type: 'warning', message: rslt.msg });
			}
		},
		onBridgeReady(data) {
			if (typeof WeixinJSBridge === "undefined") {
				Notify({ type: 'warning', message: '请在微信内打开' });
			} else {
				this.invokeWePay(data);
			}

		},
		invokeWePay(data) {
			let vm = this;
			data.openid = this.openId;
			WeixinJSBridge.invoke(
				'getBrandWCPayRequest', data,
				function(res) {
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						// 使用以上方式判断前端返回,微信团队郑重提示：
						//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						Notify({
							type: 'success',
							message: '支付成功',
							onClose: function() {

								vm.$router.push({
									path: '/user'
								})
							}
						});
					} else {

						Notify({ type: 'warning', message: '支付失败' });
					}
				});
		},
		help() {
			this.$router.push('/chat')
		},
		show() {
			this.isshow = true
			console.log(this.isshow)
		},
		// 跳转客服页面
		chat() {
			this.$router.push({ path: '/chat' })
		},
		ispaid() {},
		pay() {
			// 走到这一步，意味着已经是非vip
			// 检查 是否存在未核验订单
			// 如果存在未校验订单 提示 正在审核
			// 如果不存在未校验订单 显示二维码之类的
			this.ispay = true
			this.readychk = true
		},
		afterRead(file) {
			console.log(file)
			// 回调url 和 userinfo 组合在一起提交 表单
		},
		change() {
			console.log('changed input ')
		},
		focus() {
			this.showLable()
			console.log('focus')
		},
		showLable() {
			this.titlelable = true
		},
		cksubmit() {
			if (this.ordernumber === '') {
				Toast.fail('请填写订单号')
			}
			if (this.ordernumber.length < 10) {
				Toast.fail('请从支付宝获取正确订单号')
			}
			const ordernumber = this.ordernumber
			const username = this.username
			const agent = localStorage.getItem('agent')
			console.log('agg', agent)
			if (this.ordernumber !== '' && this.ordernumber.length >= 10) {
				addcheck(ordernumber, username, agent).then(res => {
					// console.log(res)
					// console.log('submit')
					Toast.success('请等待审核')
					this.$router.push({
						path: '/user'
					})
				})
			}

			// if (rslt.data.code === 20000) {
			//   this.$router.push({
			//     path: '/'
			//   })
			// }

			// 请求接口，
			// 请求成功后，跳转到个人中心
		}
	}
}
</script>
<style lang="less" scoped>
.guide-alipay{
	position: fixed;
	top: 20px;
	right: 20px;
	width: 61.8vw;
	background-color: rgba(1, 1, 1, 0.85);
	border-radius: 10px;
	z-index: 100000;
	border: 2px solid #efde3c;
}
.guide-alipay::after{
	content: '';
	position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 15px solid #efde3c;
    top: -15px;
    right: 15px;
}

.guide-alipay img{
	width: 100%;
}
#payPage {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.618);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.newcard {
		display: flex;
		flex-direction: column;
		background-color: rgb(255, 255, 255);
		display: -webkit-flex;
		border-radius: 2px;
		width: 81.8vw;
		// height: 50vh;
		align-items: center;
		justify-content: center;
	}
}

.top {
	// bottom: 4rem;
	top: 0;
	// height:3.5rem;
	height: 7.5vh;
	position: fixed;
	width: 100vw;
	background-color: rgba(255, 255, 255, 0.99);

	.logo {

		width: 44vw;
		// height: 5vh;
		margin-left: 20.5vw;
		margin-top: 1.2vh;
	}

	.returns {
		width: 4vh;
		// height: 5vh;
		margin-bottom: 0.4vh;
	}
}

.qrcodecard {
	display: flex;
	display: -webkit-flex;
	// border: 1px solid #000000;
	width: 100vw;
	height: 100vh;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h5 {
		background-color: white;
		width: 61.8vw;
		height: 4vh;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		text-align: center;
		padding-top: 1vh;
	}

	p {
		text-align: center;
	}

	#qrcodeimg {
		border: 1px solid #000000;
		box-shadow: 0 0 6.18vw #000000;
	}

	.btn {
		margin-top: 10vh;
	}
}

.dialogBg {
	background-color: transparent;
}

// .connect{
//   display: flex;
//   display: -webkit-flex;
//   width:100vw;
//   height:100vh;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   background-color: rgba(0, 0, 0, 0.618);
//   img{
//     border: 1px solid #000000;
//     box-shadow: 0 0 6.18vw #000000;
//   }
//   .btn{
//     margin-top:10vh;
//   }
// }

.myoverlay {
	display: flex;
	display: -webkit-flex;
	width: 100vw;
	height: 100vh;
	align-items: center;
	// justify-content: center;

	flex-direction: column;
	background-color: rgba(0, 0, 0, 0.618);

	.card {
		margin-top: 10vh;
		width: 71.8vw;
		height: 61.8vh;
		background-color: rgb(255, 255, 255);
		border-radius: 1vw;
		display: flex;
		flex-direction: column;
		// align-items: center;
		padding: 5vh;
	}
}

.btnLine {
	display: flex;

	button {
		width: 120px;
		margin: 0 10px;
	}
}
</style>